body {
  background-color: #f4f2ef;
}

textarea {
  height: 300px;
  width: 100%;
  border: 1;
  margin: 10px;
  font-size: 18px;
}

.tooltip {
  background-color: white;
}
.app_bar {
  margin: 10px;
}

.main_container {
  flex-grow: 1;
  margin: 10px;
}

a.download {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #1c65c2;
}

a.download:hover {
  text-decoration: underline;
}

.fadeAnimation {
  animation: fadeAnimation 0.2s;
}

@keyframes fadeAnimation {
  from {
    opacity: 0;
    bottom: -30px;
  }
  to {
    opacity: 1;
  }
}

.output button {
  opacity: 0.3;
}

.output:hover button {
  opacity: 0.6;
}

.MuiTypography-displayBlock {
  display: block;
  margin-left: 8px !important;
}
